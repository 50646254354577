import * as React from "react";
// import Navbar from "./components/Navbar";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/Footer";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Hablemos from "./pages/Hablemos";
import SitioEnMantenimiento from "./pages/SitioEnMantenimiento";
import "./css/app.css";

import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";

import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useLocation } from "react-router-dom";

export default function App() {
  const location = useLocation();

  const navegationLinks = [
    {
      title: "",
      path: "/",
      icon: <InboxIcon />,
    },
    {
      title: "Hablemos",
      path: "/hablemos",
      icon: <DraftsIcon />,
    },
  ];

  return (
    <div className="page-container">
      <div className="content-wrap">
        {/* <Navbar navegationLinks={navegationLinks} className="Navbar-main" /> */}
        <TransitionGroup>
          <CSSTransition key={location.key} classNames="slide" timeout={1000}>
            <Routes>
              <Route path="/" element={<SitioEnMantenimiento />} />
              <Route path="/inicio" element={<SitioEnMantenimiento />}/>
              <Route path="/hablemos" element={<SitioEnMantenimiento />} />
              <Route path="/sitioEnMantencion" element={<SitioEnMantenimiento />} />
            </Routes>
          </CSSTransition>
        </TransitionGroup>
      </div>
      <Footer />
    </div>
  );
}
