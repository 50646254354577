import React from 'react'
import '../css/hablemos.css'

function Hablemos() {
  return (
    <div className='main-hablemos'>
      <div className="img-div-section11"></div>
    </div>
  )
}

export default Hablemos