import { Container, Grid, Box, Link } from '@mui/material';

export default function Footer() {
  return (
    <footer>
      <Box
        px={{ xs: 1.5, sm: 5 }}
        py={{ xs: 2.5, sm: 5 }}
        bgcolor="black"
        color="white"
      >
        <Container maxWidth="lg">
          <Box textAlign="center" pt={{ xs: 2, sm: 1}} pb={{ xs: 2, sm: 1 }}>
            Desarrollado por OwlDev &reg; {new Date().getFullYear()}
          </Box>
        </Container>
      </Box>
    </footer>
  );
}