import React from "react";
import "../css/sitio-mantencion.css";
import imagenFondo from "../assets/img/not_found2.png"; // Asegúrate de que la ruta de la imagen es correcta

function SitioEnMantenimiento() {
  // Usamos estilo en línea para aplicar la imagen de fondo
  const backgroundStyle = {
    backgroundImage: `url(${imagenFondo})`,
    height: '100vh', // Asegura que la div toma el alto completo de la pantalla
    width: '100vw', // Asegura que la div toma el ancho completo de la pantalla
    backgroundSize: 'cover', // Cubre todo el espacio disponible sin perder proporciones
    backgroundPosition: 'center', // Centra la imagen de fondo
    backgroundRepeat: 'no-repeat' // Evita que la imagen se repita
  };

  return (
    <div className="fullScreenDiv" style={backgroundStyle}>
      {/* Puedes agregar más contenido aquí si es necesario */}
    </div>
  );
}

export default SitioEnMantenimiento;
